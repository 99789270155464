.btn-primary {
    background-color: #e0e7ea !important;
    border: none !important;
    color: #4F6B75 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.dropdown-menu {
    background-color: #e0e7ea !important;
    color: #4F6B75 !important;
}