.btnlogin {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #FFC727;
    font-weight: 600;
    font-size: 16px;
}

.loginsec {
    padding: 20px;
    border-radius: 15px;
    margin-top: 20px;
    background-color: #fff;

}

.autosteps {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    color: #253237
}

.steptext {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    margin-top: 15px;
    padding: 10px;
    color: #253237
}

.inputicon {
    text-transform: uppercase;
    background-color: rgb(255, 255, 255);
    border-width: 0.489247px;
    border-style: solid;
    border-color: rgba(137, 137, 137, 0.3) rgba(137, 137, 137, 0.3) rgba(137, 137, 137, 0.3) rgb(255, 255, 255);
    border-image: initial;
    font-weight: 500;
    cursor: pointer;
    color: rgb(255, 199, 39);
    margin-left: 0px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 13px;
}

.passwordbox {
    background-color: #fff;
    border: 0.489247px solid #dee2e6;
    border-right: 0px;
    color: #000;
    font-size: 13px;
    padding: 13px 12px;
    border-radius: 8px 0px 0px 8px
}

.passwordboxerror {
    background-color: #fff;
    border: 0.489247px solid #f20c0c;
    border-right: 0px;
    color: #000;
    font-size: 13px;
    padding: 13px 12px;
    border-radius: 8px 0px 0px 8px
}

.emailbox {
    background-color: #fff;
    border: 0.489247px solid #dee2e6;
    color: #000;
    font-size: 13px;
    padding: 13px 12px;
    border-radius: 8px
}

.emailerror {
    background-color: #fff;
    border: 0.489247px solid #f20c0c;
    color: #000;
    font-size: 13px;
    padding: 13px 12px;
    border-radius: 8px
}


.inputiconerror {
    text-transform: uppercase;
    background-color: rgb(255, 255, 255);
    border-width: 0.489247px;
    border-style: solid;
    border-color: #f20c0c #f20c0c #f20c0c rgb(255, 255, 255);
    border-image: initial;
    font-weight: 500;
    cursor: pointer;
    color: rgb(255, 199, 39);
    margin-left: 0px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 13px;
}

.errorbox {
    color: #f20c0c;
    padding: 10px;
    padding-top: 5px;
    font-size: 12px;

}

.verifyText {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-top: 15px;
    color: #253237;
}

.loginsection {
    margin-top: 3%;
    margin-bottom: 2%;
}

.logintabsec {
    padding: 30px;
    background: rgb(245, 245, 245);
    border-radius: 8px;
}

@media screen and (max-width: 550px) {
    .logintabsec {
        padding: 15px !important;
    }

    .loginsection {
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

/* 
@media screen and (max-width: 700px) {
    .loginsection {
        margin-top: 50% !important;
    }
} */