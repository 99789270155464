/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
    scroll-behavior: smooth;
    --primarycolor: #FFC727;
    --secondarycolor: #253237;
    --main-bg-color: #FFFAEA;
    --white: rgba(255, 255, 255, 0.8);
}




body {
    font-family: "Poppins", sans-serif;
    color: #253237;
}

a {
    color: #f2b40c;
    text-decoration: none;
}

a:hover {
    color: #f2b40c;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-primary {
    font-family: "Poppins", sans-serif;
}

h3 {
    font-weight: 700;
    font-size: 34px;
    color: #4e4039;
}

h6 {
    font-size: 30px;
}

p {
    margin: 0;
}

/*************  Common CSS *************/

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.p15 {
    padding: 15px;
}

.fs12 {
    font-size: 12px;
}

.fs14 {
    font-size: 14px;
}

.fs16 {
    font-size: 16px;
}

.fs20 {
    font-size: 20px !important;
}

.fs24 {
    font-size: 24px;
}

.fw400 {
    font-weight: 400;
}

.fw500 {
    font-weight: 500;
}

.fw600 {
    font-weight: 600;
}

.fw700 {
    font-weight: 700;
}

.divcenter {
    display: grid;
    align-content: center;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: var(--primarycolor);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: var(--primarycolor);
    color: #fff;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
    height: 72px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    background: #fff;
    margin: 20px auto;
    border-radius: 5px;
    background-color: #E0E7EA;
}

#header.headerindex {
    background-color: unset;
}

#header .logo h1 {
    font-size: 30px;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #7a6960;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}


/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 15px;
    color: #4e4039;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #4e4039;
}

.navbar .getstarted,
.navbar .getstarted:focus {
    background: #eb5d1e;
    color: #fff;
    padding: 10px 25px;
    margin-left: 30px;
    border-radius: 50px;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
    color: #fff;
    background: #ee7843;
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #eb5d1e;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
    color: #7a6960;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(78, 64, 57, 0.9);
    transition: 0.3s;
    z-index: 1;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #7a6960;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: var(--primarycolor);
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #eb5d1e;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # home Section
  --------------------------------------------------------------*/
#home {
    width: 100%;
    /*height: 70vh;*/
    background: #ffffff;
    /*margin: 72px 0 -72px 0;*/
}

#home h2 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;

}

#home h3 {
    margin: 15px 0 0 0;
    font-size: 24px;
    color: var(--secondarycolor);
    font-weight: 500;

}

#home .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 0px;
    border-radius: 3px;
    transition: 0.5s;
    margin-top: 24px;
    color: #253237;
    background: #FFC727;
    margin-right: 24px;
    width: 200px;
    text-align: center;
}

#home .btn-get-secondry {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 0px;
    border-radius: 3px;
    transition: 0.5s;
    margin-top: 24px;
    color: #253237;
    background: transparent;
    border: 1px solid #FFC727;
    margin-right: 24px;
    width: 200px;
    text-align: center;
}

#home .btn-get-started:hover {
    background: #f2b40c;
}

.web3-arrow {
    position: absolute;
    top: 22%;
}

.web3-btn {
    position: relative;
}

#home .btn-get-secondry.web3-btn.callogbtn {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #FFC727;
}

#home .cal-btn {
    padding: 24px
}

#home .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 991px) {
    /*#home {
      height: calc(100vh - 72px);
    }*/

    #home .animated {
        animation: none;
    }

    #home .hero-img {
        text-align: center;
    }

    #home .hero-img img {
        width: 50%;
    }
}

@media (max-width: 768px) {
    #home h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #home h2 {
        font-size: 18px;
        line-height: 24px;
    }

    #home .hero-img img {
        width: 70%;
    }
}

@media (max-width: 575px) {
    #home .hero-img img {
        width: 80%;
    }

    #header.headerindex {
        background-color: #E0E7EA;
    }

    #footer .col-lg-8 {
        padding: 15px;
    }

}

@media (max-height: 600px) {
    /*#home {
      height: 120vh;
    }*/
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.section-bg {
    background-color: #E0E7EA;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 0;
    line-height: 1px;
    margin-bottom: 15px;
    color: #c2b7b1;
}

.section-title p {
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;
    font-size: 32px;
    font-weight: 700;
    color: #4e4039;
}

.section-title p::after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 2px;
    background: #eb5d1e;
    bottom: 0;
    left: calc(50% - 30px);
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
    padding: 15px 0;
    background-color: #fef5f1;
    min-height: 40px;
}

.breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
}

@media (max-width: 768px) {
    .breadcrumbs .d-flex {
        display: block !important;
    }

    .breadcrumbs ol {
        display: block;
    }

    .breadcrumbs ol li {
        display: inline-block;
    }
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/


.anft i {
    font-size: 48px;
    margin-top: 15px;
    color: #f39e7a;
}

/*
  .anft p {
    font-size: 15px;
    color: #5a6570;
  }*/

@media (max-width: 991px) {
    .anft .about-img img {
        max-width: 70%;
    }
}

@media (max-width: 767px) {
    .anft .about-img img {
        max-width: 90%;
    }

    .aligncenter {
        justify-content: center !important;
        flex-wrap: wrap;
    }
}

/*--------------------------------------------------------------
  # statistics
  --------------------------------------------------------------*/
.statistics .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    margin: 0 0 10px 0;
    background: var(--secondarycolor);
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    text-align: center;
    border-bottom: 3px solid #fff;
    width: 240px;
    height: 160px;
}

.statistics .icon-box:hover {
    transform: translateY(-5px);
    border-color: var(--primarycolor);
}

.statistics .icon i {
    font-size: 48px;
    line-height: 1;
    margin-bottom: 15px;
    color: var(--primarycolor);
}

.statistics .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 22px;
    color: #ffbd00;
}

.statistics .title a {
    color: #ffbd00;
    font-size: 20px;
}

.statistics .description {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 0;
    /*color: var(--secondarycolor);*/
    color: #FFC727;
}

/* .icon-box{
    background: #253237;
    text-align: center;
    color: #FFC727;
    padding: 20px;
    border-radius: 8px;
  } */

#statistics .row {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
}

/*--------------------------------------------------------------
  # Miners
  --------------------------------------------------------------*/

.miner-accord {
    width: 60%;
    margin: 0 auto;
}

#accordionExample .accordion-button {
    background-color: #FFFAEA;
    font-size: 16px;
    color: #253237;
}

.accord-content {
    border: 1px solid var(--primarycolor);
    border-radius: 4px;
    text-align: center;
    padding: 30px;
}

.accord-content span {
    color: var(--primarycolor);
    font-size: 20px;
}

.accord-content p {
    font-size: 20px;
}

/* .accordion-button:focus{
    border-color: var(--primarycolor);
  } */


/*stacklist*/
.stacklist {
    margin-top: 20px;
    padding: 0;
    border: 1px solid var(--primarycolor);
    border-radius: 4px;
}

.stacklist li {
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    display: flex;
    font-size: 18px;
    justify-content: space-between;
    padding: 15px 10px;
}

.stacklist li label {
    color: #253237;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.stacklist li span {
    color: #253237;
    font-weight: 700;
    text-align: right;
}

.ybbtndiv {
    text-align: center;
}

.startstackingbtn {
    background: #ffc727;
    border-radius: 4px;
    color: #253237;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin-top: 15px;
    padding: 17px;
    width: 100%;
    border: unset;
}

/*--------------------------------------------------------------
  # how-it-work
  --------------------------------------------------------------*/
#how-it-work {
    background-color: #fff;
}


.how-works {
    width: 50%;
    margin: 0 auto 20px;
    position: relative;
    background-color: var(--main-bg-color);
    border-radius: 8px;
    padding: 10px;
}

.how-works p {

    margin-left: 40px;
    /*margin-top: 15px;*/
    font-size: 18px;
    color: #253237;
    font-weight: 600;
}

.how-works span {
    height: 50px;
    width: 50px;
    position: absolute;
    background-color: var(--primarycolor);
    left: -4%;
    border-radius: 50px;
    /*text-align: center;*/
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    font-size: 30px;
    font-weight: 500;

}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
    background: #4F6B75;
    /*padding: 0 0 30px 0;*/
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 30px;
}


#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ffffff;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.aligncenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    /*background: var(--primarycolor);*/
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.footer-top .social-links a:hover {
    background: var(--primarycolor);
    /*color: #fff;*/
    color: var(--secondarycolor) !important;
    text-decoration: none;
}

#footer .copyright {
    text-align: center;
    float: left;
}

#footer .credits {
    float: right;
    text-align: center;
    font-size: 13px;
    color: #212529;
}

#footer .credits a {
    color: var(--primarycolor);
}

@media (max-width: 575px) {

    #footer .copyright,
    #footer .credits {
        float: none;
        -moz-text-align-last: center;
        text-align-last: center;
        padding: 3px 0;
    }
}


/*--------------------------------------------------------------
  # mobile
  --------------------------------------------------------------*/

@media (max-width: 575px) {
    .miner-accord {
        width: 100%;
        margin: 0 auto;
    }

    .how-works {
        width: 95%;
    }

    #statistics .row {
        width: 100%;
        margin: 0 auto;
    }

    .statistics .icon-box {
        padding: 30px;
        position: relative;
        overflow: hidden;
        margin: 0 auto 30px;
        /*background: #fff;*/
        background: #253237;
        box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
        transition: all 0.3s ease-in-out;
        border-radius: 8px;
        text-align: center;
        border-bottom: 3px solid #fff;
        width: 240px;
        height: 140px;
    }

    h3 {
        font-weight: 700;
        font-size: 24px;
        color: var(--secondarycolor);
    }

    h6 {
        font-size: 20px;
    }

    .anft img {
        width: 80%;
    }

    .home-btn {
        text-align: center;
    }

    /*  #home {
      height: 100vh;
    }*/

    #home h2 {
        margin: 0;
        font-size: 34px;
        font-weight: 700;
        line-height: 56px;
        text-align: center;

    }

    #home h3 {
        margin: 15px 0 0 0;
        font-size: 16px;
        color: var(--secondarycolor);
        font-weight: 500;
        text-align: center;

    }

    .callogbtn {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

    }

    .calcell,
    .respmb {
        margin-bottom: 20px;
    }




    #footer {
        padding: 0px;
    }

    section {
        padding: 40px 0;
    }

    /* #home h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #home h2 {
      font-size: 18px;
      line-height: 24px;
    }
  
    #home .hero-img img {
      width: 70%;
    } */

}



.calculator-bg {
    background-color: #4F6B75;
    padding: 30px;
    border-radius: 8px;
}

.calculatorSec {
    display: flex;
    justify-content: space-between;
}

.calcell {
    font-size: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #ffffff;
    padding: 15px;
    border-radius: 8px;
    justify-content: center;

}

.calcell span {
    font-size: 12px;
}

.calculatorBtn,
.calculatorBtn:hover {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    font-size: 32px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #FFC727;
    padding: 15px;
    border-radius: 8px;
    justify-content: center;
    padding: 12px 20px;
    border-radius: 8px;
    transition: 0.5s;
    color: #253237;
    background: #FFC727;
    text-align: center;
}

.calculatorBtn img {
    margin-right: 10px;
}

.anftp,
.aboutp {
    font-size: 20px;
    color: #253237;
}

/************* Available Miner *************/

.minerAvailableCard {
    background-color: #F5F5F5;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.minerAvailableTitle {
    font-size: 16px;
    text-align: center;
    color: #253237;
}

.minerAvailableTitle span {
    font-weight: 600;
}

.minerAvailableImage {
    display: flex;
    justify-content: center;
}

.minerAvailablebtn {
    width: 100%;
    display: flex;
    padding: 5px;
    border: 1px solid #ddd;
    justify-content: center;
    color: #000;
    margin-top: 10px;
}

.minerAvailablebtn:hover {
    cursor: pointer;
    /*color: #000;*/
}

/************* footer **********/

.footerimg {
    width: 32px;
}

.footerlist {
    display: flex;
    gap: 5px;
    font-size: 16px;
}

#bottomFooter {
    background: var(--secondarycolor);
}

.bottomFooterp {
    font-size: 12px;
    color: #A0AEC0;
    padding: 18px;
    margin: 0;
}

.social-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}





/************ Pages **************/
.darksec-bg {
    background-color: #4F6B75;
}

.lightblue-bg {
    background-color: #E0E7EA;
}

.lightgrey-bg {
    background-color: #EEEEEE;
}

.dimgrey-bg {
    background-color: #F5F5F5;
}


.secpadding {
    padding: 15px;
}

.brtlr,
.detailCard.brtlr {
    border-radius: 8px 8px 0px 0px;
}

.brblr {
    border-radius: 0px 0px 8px 8px;
}

.brrtb {
    border-radius: 0px 8px 8px 0px !important;
}

.brltb {
    border-radius: 8px 0px 0px 8px !important;

}

.navbar .disconnetbtn,
.navbar .disconnetbtn:focus,
.navbar .disconnetbtn:hover {
    padding: 5px 25px;
    border-radius: 8px;
    color: #ffffff;
    background-color: #4F6B75;
}

.navbar .disconnetbtn:hover {
    cursor: pointer;
}

.btn-bg-yellow {
    height: 37px;
    padding: 0px 10px;
    min-width: 132px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: #253237;
    background-color: #FFC727;
    float: right;
    text-align: center;
}

.btn-bg-yellow:hover {
    color: #253237;
}

.home-btn {
    max-width: 428px;
    margin-top: 24px;
}

.logsignsec {
    padding: 20px;
    border-radius: 8px;
}

.mainsection,
.mobileheader {
    max-width: 600px;
    margin: auto;
}

.connectto {
    padding: 10px;
    border: 1px solid #4F6B75;
    border-radius: 8px;
    text-align: center;
}


.text1 {
    font-size: 12px;
    color: var(--secondarycolor);
}

.text2 {
    font-size: 12px;
    color: #2D3748;
}

.walletsecp {
    font-size: 10px;
    color: #A0AEC0;
}

.walletsecp2,
.selectcuramt {
    font-size: 14px;
    color: var(--secondarycolor);
    font-weight: 700;
}

.minertabsec ul li {
    width: 25%;
}

.minertabsec .nav-link.typetabbtn,
.wallettabsec .nav-link.typetabbtn {

    border: 1px solid #EEEEEE;
    background: white;
    color: var(--secondarycolor);
    border-radius: unset;
    width: 100%;
    font-weight: 500;

}

.minertabsec .nav-link.typetabbtn.active,
.wallettabsec .nav-link.typetabbtn.active {
    color: var(--secondarycolor);
    background: var(--primarycolor);

}

.backbtnsec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4F6B75;
    font-size: 18px;
    font-weight: 700;
}

.backbtn,
.backbtn:hover {
    display: flex;
    gap: 5px;
    color: #4F6B75;
    align-items: center;

}

.backbtn i {
    height: 25px;
    display: flex;
    width: 25px;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border-radius: 50%;
    background-color: #4F6B75;
    color: #ffffff;
}

.detailCard {
    background-color: #ffffff;
    border-radius: 8px;
}

.headingtext2 {
    font-size: 20px;
    font-weight: 700;
    color: var(--secondarycolor);
    margin-bottom: 10px;
}


.btn-outline-color-secondary,
.btn-outline-color-secondary:hover {
    padding: 10px 0px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    color: var(--secondarycolor);
    justify-content: center;
    align-items: center;
    border: 1px solid var(--secondarycolor);
    gap: 5px;
}

.btn-outline-grey,
.btn-outline-grey:hover {
    padding: 5px 30px;
    border: 1px solid #dddd;
    border-radius: 5px;
    color: #253237;
    text-align: center;
}

.btn-outline-grey:hover {
    cursor: pointer;
}



.minerspectable .lightblue-cell-bg {
    background-color: #C1CFD5;
}

.minerspectable th {
    font-size: 12px;
}

.minerspectable th span,
.selectcurpricep {
    font-size: 10px;
    font-weight: 400;
}

.minerspectable td {
    font-size: 16px;
    color: #000000;
}

.minerspectable td.green,
.greentext {
    color: #36B37E;
}

.minerspectable td.red,
.redtext {
    color: #FF0000;
}

.yellowtext {
    color: #FFC727;
}

/********** Select Currency ************/


.selectcurrow,
.selectedminer,
.wallettokenrow {
    border: 1px solid #E4E5E5;
    border-radius: 8px;
    margin-bottom: 20px;
}

.selectedminer {
    background-color: #ffffff;
}

.selectcurrowcontent,
.selectedminercontent {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;

}

.selectcurrowcontent.darksec-bg {
    background-color: #4F6B75;
    color: #ffffff;
    border: 1px solid #4F6B75;
    border-radius: 8px;
}

.selectcurrowcontent.darksec-bg .selectcuramt {
    color: #ffffff;
}

.selectcurimgside {
    display: flex;
    align-items: center;
    gap: 10px;
}

.selectcurfocused {
    display: flex;
    justify-content: space-between;
    background-color: #E0E7EA;
    border-radius: 0px 0px 8px 8px;
    padding: 5px 10px;
}

.selectcurpricep span {
    margin-left: 5px;
}

.selectcurbtnsec {
    display: flex;
    gap: 30px;

}

.selectcurbtnsec a {
    width: 50%;
}

.minerimg {
    width: 60px;
}


/***************** Miner Accordian *************/

#accordionExample .accordion-button.mineraccordianbtn {
    background-color: #ffffff;

}

.mineraccordianbtn {
    gap: 25%;
    border-radius: 8px 8px 0px 0px;
}

.mineraccordianbtn:after {
    background-image: url('./images/accordian-btn-icon.svg');
    width: 12px;
    height: 8px;
    background-size: contain;
}

.mineraccordianbtn:not(.collapsed)::after {
    background-image: url('./images/accordian-btn-icon.svg');
    transform: rotate(180deg);
}

.mineraccordianbtn.collapsed {
    border-radius: 8px;
}

.mineraccordian .minerlist,
.mineraccordian .mineraccordianbody {
    padding: 0;
    margin: 0;
}

.mineraccordianitem {
    margin-bottom: 20px;
    border-radius: 8px;
}

.mineraccordian .minerlist li {
    border: 1px solid #d9d9d9;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding: 15px 10px;
    width: 100%;
}

.mineraccordian .minerlist li label {
    color: #253237;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.mineraccordian .minerlist li span {
    color: #253237;
    font-weight: 700;
    text-align: right;
}

/************** Wallet ************/

.sendreceivesec {
    padding: 20px;
    border-radius: 8px;
}

.walletamtp {
    font-size: 40px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    margin: 20px 0px 40px 0px;
}

.walletamtp2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
}

.sendbtn,
.receivebtn,
.sendbtn:hover,
.receivebtn:hover {
    padding: 10px;
    background-color: #E0E7EA;
    color: #3A4E56;
    border: 1px solid #4F6B75;
}

.wallettabsec ul li {
    width: 50%;
}

.wallettabsec .nav-link.typetabbtn {
    padding: 5px 0px;
}

.wallettokenrow {
    background-color: #ffffff;
}

.tranheadp,
.trandelp {
    font-size: 20px;
    color: #000000;
    font-weight: 500;
}

.trandelp {
    font-size: 16px;
    color: #253237;
}

/************ Send *********/

.inputdetailp {
    background-color: #E0E7EA;
    padding: 20px;
    border-radius: 8px;
    font-size: 14px;
    color: #4F6B75;
}


/********** Receive *************/

.barcodeimg {
    text-align: center;
    width: 85%;
    margin: auto;
}

.copysharegroup {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.barcodenop {
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    word-wrap: break-word;
}

.featurebtn p {
    color: #4F6B75;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
}

.featureimg {
    padding: 10px;
    background-color: var(--primarycolor);
    border-radius: 100px;
}


/************ Transaction type ****************/

.transctionamtp {
    font-size: 36px;
    color: #FF5656;
    text-align: center;
}

.singleDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #4F6B75;
    flex-wrap: wrap;
}

.wordbreak {
    word-break: break-word;

}

.swapcurimg,
.collectearnimg {
    width: 34px;
}

.swapiconrow {
    display: flex;
    gap: 5px;
    align-items: center;
}

.swapdel p {
    margin: 0px;
}

.arrowdownimg {
    margin-left: 45px;
}


/************** Refer ************/


.borderbottom {
    border-bottom: 1px solid #E2E8F0;
}

.referinvitep {
    text-align: center;
    color: #ffffff;
}

.refertilerow {
    display: flex;
    gap: 30px;
}

.refertilerow .refertile {
    width: 50%;
}

.refertile {

    padding: 15px;
    text-align: center;
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 20px;
}

.tilesphead {
    color: #2C3356;
    font-size: 16px;
    font-weight: 600;
}

.tilespdel {
    font-size: 20px;
    color: #4F6B75;
    font-weight: 700;
}

.reftablesec {
    display: flex;
    justify-content: space-between;
    gap: 30px;

}

.reftablesec .rankpertable,
.reftablesec .downlinepertable {
    width: 50%;
}

.tablebg1,
.tablebg2,
.tablebg3,
.tablebg4 {
    text-align: center;
    width: 25%;
    font-size: 12px;
    padding: 10px;
    font-weight: 700;
    color: #000000;
}

.tablebg1.active,
.tablebg2.active,
.tablebg3.active,
.tablebg4.active {
    background-color: var(--primarycolor);
}

.downlinepertable table,
.rankpertable table {
    border-color: #ffffff;
}

.tablebg1 {
    background-color: #C1CFD5;
}

.tablebg2 {
    background-color: #E0E7EA;
}

.tablebg3 {
    background-color: #ECECEC;
}

.accordion-button.colearnaccobtn:after {
    display: none;
}

.accordion-button.colearnaccobtn {
    justify-content: space-between;

}

.accordion-button.colearnaccobtn .colearndel img {
    transition: transform 0.2s ease-in-out;
}

.accordion-button.colearnaccobtn:not(.collapsed) .colearndel img {
    transform: rotate(180deg);
}

.colearnicon,
.colearndel {
    display: flex;
    gap: 10px;
    align-items: center;
}

.colearniconp,
.colearnp {
    font-size: 16px;
    color: #253237;
    font-weight: 700;
}

.colearnp2 {
    font-size: 10px;
    font-weight: 400;
    text-align: end;
}

.darktrbg {
    background-color: #648795;
}

.lightdarkbg {
    background-color: #E0E7EA;
}

.whitebg {
    background-color: #ffffff;
}

.rankbonustable table {
    border: 2px solid #F5F5F5;
}

.whitebg.active,
.lightdarkbg.active {
    background-color: var(--primarycolor);
}

.whitebg,
.lightdarkbg,
.darktrbg {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #000000;
}



/*********** Stats ********/

.bardelshowp {
    border: 1px solid #C1CFD5;
    color: #253237;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
}

.miningcapacitytype,
.unitsoldtype {
    display: none;
}

.primarybardot,
.secondarybardot,
.sfirstbg,
.ssecbg,
.sthirdbg {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: var(--primarycolor);
}

.secondarybardot {
    background-color: #4F6B75;
}

.sfirstbg {
    background-color: #FFF6DC;
}

.ssecbg {
    background-color: #FFE18C;
}

.sthirdbg {
    background-color: #EBAE00;
}

/************ Your Downline **************/


.downlinesec {
    border-radius: 8px;

    box-shadow: 0px 0px 3px 0px #3B4E5266;


}

.downlinedetail {
    max-width: 600px;
    box-shadow: 0px 0px 3px 0px #3B4E5266;
    border-radius: 8px;
}

.downlinedelsec {
    background-color: #ffffff;
}

.downlinedata {
    display: flex;
    justify-content: space-between;

}

.exploreback a {
    color: #3B4E52;
    font-size: 20px;
}

.yourdownline {
    background-color: #EFF3F4;
    overflow: scroll;
    border-radius: 8px;

}

.downlinerow {
    margin-bottom: 20px;
}

.downlineaccordion {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.downlineicon {
    margin-right: 10px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4F6B75;
    color: #ffffff;
}

.downlinecontent {
    display: none;
    margin-left: 40px;
    margin-top: 10px;
}

.downlineactive {
    display: block;
}

.downlinep {
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;

}

.downlinep span {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primarycolor);
    color: #000000;
    font-size: 12px;
}

/************** Loader Animation *********/


@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-image {
    animation: loader 2s linear infinite;
}






/*************** Media Queries **************/

@media (max-width: 520px) {
    #header {
        margin: 10px;
        height: 50px;
    }

    #header .logo img {
        max-height: 20px;
    }

    .tabimg {
        width: 12px;
    }

    .mainsection,
    .secpadding,
    .logsignsec {
        padding: 10px;
    }

    .anftp,
    .aboutp {
        font-size: 20px;
    }

    .how-works span {
        height: 30px;
        width: 30px;
        font-size: 20px;
    }

    .navbar .disconnetbtn,
    .navbar .disconnetbtn:focus {
        padding: 5px 15px;

    }

    .footerlist {
        width: 50%;
        transform: translate(50%, 50%);
    }

    .footerlist,
    .navbar .disconnetbtn,
    .navbar .disconnetbtn:focus {
        font-size: 12px;
    }

    .minertabsec .nav-link.typetabbtn {
        padding: 11px 0px;
        font-size: 10px;
    }

    .manageaccorheadp {
        font-size: 12px;
    }

    .minerAvailablebtn {
        font-size: 10px;
    }

    .headingtext2,
    .exploreback a {
        font-size: 14px;
    }

    .minerspectable td {
        font-size: 10px;
    }

    .walletsecp2,
    .selectcuramt,
    .backbtn,
    .backheadmed,
    .trandelp,
    .btn-bg-yellow,
    .calculatorBtn,
    .downlinep,
    .text1,
    .form-select,
    .lifetimep,
    .barcodenop,
    .featurebtn p {
        font-size: 12px;
    }

    .btn-outline-grey,
    .btn-outline-grey:hover {
        padding: 5px 10px;
        font-size: 12px;
    }

    .curicondel {
        font-size: 12px;
    }

    .footersocialicon {
        width: 25px;
    }

    .minerimg {
        width: 40px;
    }

    .connectto p,
    .minerAvailableTitle,
    .btn-outline-color-secondary,
    .text2,
    .tilespdel,
    .tilesphead,
    .bardelshowp {
        font-size: 10px;
    }

    .btn-color-primary,
    .btn-color-primary:hover,
    .btn-outline-color-secondary:hover,
    .referinvitep,
    .btn-bg-yellow {
        font-size: 10px;
    }

    .walletamtsec p.text-white {
        font-size: 12px;
    }

    .minerspectable th span,
    .selectcurpricep {
        font-size: 8px;
    }

    .social-links {
        justify-content: space-around;
        background-color: #3A4E56;
        padding: 10px;
    }

    .btn-color-primary,
    .btn-color-primary:hover,
    .btn-outline-color-secondary,
    .btn-outline-color-secondary:hover {
        padding: 5px 0px;
    }

    .calcell,
    .calculatorBtn {

        width: 100%;
    }

    .selectcurbtnsec,
    .refertilerow,
    .reftablesec {
        gap: 15px;
    }

    .transctionamtp {
        font-size: 28px;
        text-align: center;
    }

    .reftablesec {
        flex-wrap: wrap;
    }

    .reftablesec .rankpertable,
    .reftablesec .downlinepertable {
        width: 100%;
    }

    select#selectOption {
        font-size: 12px;
    }

    .rankbonustable tr {
        font-size: 12px;
    }

    .downlinedata {
        flex-wrap: wrap;
        gap: 20px;
    }

    .textend {
        text-align: end;
    }

    .walletsecp {
        font-size: 10px;
    }

    .minerAvailableCard,
    .refertile {
        padding: 10px;
    }

    .mineraccordianbtn {
        gap: 15%;
    }

    hr {
        margin: 10px 0px;
    }

    .colearniconp,
    .colearnp {
        font-size: 12px;
    }

    #accordionExample .accordion-button.mineraccordianbtn {
        padding: 10px;
    }

    .wallettabsec .nav-link.typetabbtn {
        padding: 10px 0px;
        font-size: 12px;
    }

    .inputdetailp {
        font-size: 12px;
        padding: 10px;
    }
}




/************** Egold Project ***************/
.w22 {
    width: 22px;
}

.egoldcommunity .accordion-button::after {
    background-image: url("./images/plus.svg");
}

.egoldcommunity .accordion-button:not(.collapsed)::after {
    background-image: url("./images/minus.svg");
}

.egoldcommunityitem {
    margin-bottom: 15px;
}

.egoldcommunitybtn {
    padding: 0px 20px 0px 0px;
    background-color: #4f6b75;
}

.egoldcommunitybtn:not(.collapsed) {
    background-color: #4f6b75;
}

.egoldaccordianimg {
    padding: 15px;

    background-color: var(--primarycolor);
}

.egoldcommunitybtnp {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    padding-left: 20px;
}

.egoldaccordianimg img {
    width: 36px;
    height: 36px;
}

.egoldcommunitybody {
    padding: 20px;
    background-color: #E0E7EA;
}

.egoldcommbtns {
    margin-top: 10px;
    display: flex;
    justify-content: end;
    gap: 20px;
}

.egoldcommbtns a {
    color: #000000;
}

.tokenomicsrow {
    border-radius: 16px;
}

.tokenmicdel1 {
    background-color: #A1B7C0;
}

.tokenmicdel2 {
    background-color: #C1CFD5;
}

.tokenmicdel3 {
    background-color: #E0E7EA;
}

.tokenmicimg {
    display: flex;
    justify-content: center;
}

.tokenmicdel1,
.tokenmicdel2,
.tokenmicdel3 {
    padding: 20px;
}

.tokenmicimgp {
    font-size: 22px;
    color: #4F6B75;
    font-weight: 600;
    text-align: center;
}

.tokenmicimgp2 {
    font-size: 20px;
    color: #4F6B75;
    font-weight: 400;
    text-align: center;
}

.tokennumber {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 18px;
}

.tokennum {
    background-color: #4F6B75;
    color: #ffffff;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.tokenicon {
    height: 48px;
    width: 48px;
    background-color: #525252;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.tokenmicdelicon {
    display: flex;
    gap: 25px;
    justify-content: center;
    margin-top: 18px;
}

.tokenomicsp {
    padding: 30px;
    font-size: 14px;
    color: #253237;
    text-align: justify;
}


/************* Mine *************/

.egoldsubtabsec ul li {
    width: 33.33%;

}

.egoldsubtabsec .nav-link.typetabbtn {
    text-transform: uppercase;
    background-color: #E0E7EA;
    color: #648795;
    border: 1px solid #648795;
    align-items: center;
}

.egoldsubtabsec .nav-link.typetabbtn.active {
    color: #ffffff;
    background-color: #648795;
}

.minerownedsec {
    background-color: #4F6B75;
    color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}

.minersownedp {
    font-size: 14px;
}

.minerownep {
    font-size: 12px;
    color: #FFFFFF;
}

.minerownep2 {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 500;
}

.minerstate {
    text-align: center;
}

.mineaccorsec {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    align-items: center;
}

.mineaccorbtnsec {
    width: 60%;
}

.mineaccorbtns {
    display: flex;
    width: 100%;
    gap: 15px;
}

.mineaccorbtn,
.mineaccorbtn:hover {
    background-color: #E0E7EA;
    padding: 10px;
    display: flex;
    width: 50%;
    justify-content: center;
    color: #4F6B75;
    border-radius: 4px;

}

.mineaccorbtn.active,
.mineaccorbtn.active:hover {
    background-color: #648795;
    color: #ffffff;
}

.mineaccorimg {
    width: 117px;
    height: 72px;
    margin-bottom: 5px;
}

.minerownedpsec {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
}

.performancehistroy {
    display: flex;
    justify-content: space-between;
    background-color: #E0E7EA;
    padding: 20px;
    color: #4F6B75;
    flex-wrap: wrap;
    gap: 15px;
}

.performancehistroy>div {
    flex: 1;
    margin: 0 5px;
}

#accordionExample .accordion-button.mineraccordianbtn.minebtn {
    background-color: #E0E7EA;
    color: #4F6B75;
}




.appactiveminer {
    display: flex;
    gap: 30px;
    padding: 10px 20px;
    background-color: #C1CFD5;
}

.performancehistroy .minerownep2.redtext {
    color: #FF0000;
}

.performancehistroy .minerownep,
.performancehistroy .minerownep2 {
    color: #4F6B75;
}


.customrange {
    width: 100%;
    height: 24px;
    -webkit-appearance: none;
    background: #E0E7EA;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset 0 0 5px #E0E7EA;
}

.customrange::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #4F6B75;
    cursor: pointer;
    box-shadow: -410px 0 0 400px #82A0AB;
}

.pr {
    position: relative;
}


.updownimgb {
    background: #FFB703;
    width: 20%;
    border-radius: 4px;
    padding: 10px 0px;
    text-align: center;
    position: absolute;
    bottom: -30px;
    left: 40%;
}

.fromreactp {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
}

.fromreactspan {
    font-size: 14px;
    color: #000000;
    font-weight: 700;
}

.float-right {
    float: right;
}


.fromreactangle {
    border: 1px solid #E4E5E6;
    border-radius: 8px;
}

.rangevalue p {
    color: #253237;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.swapdelrow {
    display: flex;
    justify-content: space-between;
    background-color: #E0E7EA;
    padding: 15px;
}

.swapdelrow p {
    font-size: 12px;
    color: #253237;
    font-weight: 600;
}

.bbff {
    border-bottom: 1px solid #ffffff;
}

.volhour {
    padding: 10px;
    background-color: #E0E7EA;
    border-radius: 4px;
}

.volhourp {
    text-align: center;
    font-size: 12px;
}

.latesttradesec ul li {
    width: 50%;
}

.lightredbg {
    background-color: #FFEBEB;
}

.lightgreenbg {
    background-color: #E8FEFC;
}

.latesttradetable table {
    border: 2px solid #F5F5F5;
    margin-bottom: 0px;
}

.latesttradetable td {
    font-size: 16px;
    font-weight: 700;
    color: #4F6B75;

}



/************ Stake ************/

.stakeliverow {
    margin-bottom: 20px;
}

.stakelivebtn,
.stakelivebtn:hover {
    padding: 6px 15px;
    background-color: #648795;
    color: #ffffff;
    border-radius: 4px;
    font-size: 12px;
}

.livebadge {
    font-size: 10px;
    color: #4FD1C5;
    border: 1px solid #4FD1C5;
    padding: 2px 15px;
    border-radius: 10px;
    float: right;
}

.slfirst {
    margin-bottom: 20px;
}

.slsecond {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slthird {
    padding: 10px;
    background-color: #E0E7EA;
    display: flex;
    justify-content: space-between;
}

.slfsgroup {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
}

.slfourth {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: #C1CFD5;
    border-radius: 0px 0px 8px 8px;
    transition: transform 0.3s ease-in-out;
}

.slfourthbtns {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.slfourthbtn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0px;
}

.slcollectbtn.active,
.slstakebtn.active {

    color: #253237;
    background-color: #FFC727;
    border: 1px solid #FFC727;
}

.slcollectbtn,
.slstakebtn {
    width: 100px;
    text-align: center;
    color: #3A4E56;
    border: 1px solid #82A0AB;
    border-radius: 4px;

}

.slfourthdel {
    padding: 10px;
    background-color: #C1CFD5;
}

.slide-down-content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
}

.slide-down-content.active {
    height: auto;
    border-radius: 0px 0px 8px 8px;
}

.slfourth.active {
    border-radius: 0px;
    border-bottom: 1px solid #A1B7C0;
}

/*.slfourth.active img{
    transform: rotate(90deg);
  }*/


.tokenmorebtsn {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.slidecontent {
    padding: 10px;
    background-color: #C1CFD5;
}

.slidecontentp {
    font-size: 14px;
    margin-bottom: 10px;
}

.tokenmorebtsn span {
    display: flex;
    align-items: center;
    gap: 5px;
}


.sliderinput {
    position: relative;
    margin-bottom: 5px;
}

.sliderinput input {
    font-size: 14px;
}

.labelmax {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: #648795;
    height: 100%;
    display: flex;
    width: 64px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FFC727;
}

.labelusemax {
    position: absolute;
    right: 0px;
    top: 0px;

    height: 100%;
    display: flex;
    width: 64px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #FFC727;
}

.sliderinput.mb15 {
    margin-bottom: 15px;
}

.labelegold {
    position: absolute;
    right: 68px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4F6B75;

}

.sliderlabel {
    font-size: 14px;
}

.labelavailbalp {
    text-align: end;
    font-size: 12px;
    margin-bottom: 10px;
}

.stakegoldp {
    font-size: 14px;
    color: #4F6B75;
}

.transferinput,
.transferlabel {
    color: #2D3748;
    font-size: 12px;
}

.completedbadge {
    font-size: 10px;
    color: #FF5656;
    border: 1px solid #FF5656;
    padding: 2px 15px;
    border-radius: 10px;
    float: right;
}

.upcomingbadge {
    font-size: 10px;
    color: #FFC727;
    border: 1px solid #FFC727;
    padding: 2px 15px;
    border-radius: 10px;
    float: right;
}

.inactivebadge,
.expirebadge {
    font-size: 10px;
    color: #FF5656;
    border: 1px solid #FF5656;
    padding: 2px 15px;
    border-radius: 10px;
}

.activebadge {
    font-size: 10px;
    color: #4FD1C5;
    border: 1px solid #4FD1C5;
    padding: 2px 15px;
    border-radius: 10px;
}

.activeminerbtn {
    position: relative;
}

.activeminertext {
    position: absolute;
    top: 16px;
    right: 40px;
}


.bluetext {
    color: #61D2F5 !important;
}

/**************** Wallet Popup ************/

.wallettokencell {
    display: flex;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    align-items: center;
}

.wallettokenimg {
    width: 24px;
}

.wallettoken {
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.walletokenp {
    font-size: 12px;
}

.walletrowimgsec {
    display: flex;
    gap: 5px;
    align-items: center;
}

.walletrowimg,
.pingimg {
    width: 24px;
}

.walletrow {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-top: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.walletpingsec {
    display: flex;
    gap: 10px;
    align-items: center;
}


.walletbadge {
    text-align: center;
    font-size: 8px;
    background-color: #dddddd;
    color: #666666;
    padding: 3px;
    height: 16px;
    width: 34px;
    border-radius: 8px;
}

.walletpingdelprice {
    font-size: 10px;
    text-align: end;
}

.walletpingp {
    font-size: 14px;
    color: #253237;
    font-weight: 700;
}

.walletpopupfooterp {
    font-size: 12px;
}

.wallettokenheaderbg {
    background-color: #F5F5F5;
}

.selecttokendrop:hover {
    cursor: pointer;
}

.width20 {
    width: 20px;
}

/***************** Media Queries *************/

@media (max-width: 520px) {

    /*.mineaccorsec {
    flex-wrap: wrap;
  
  
  }
  .mineaccorbtnsec{
    width: 100%;
  }
  .mineaccorbtn,.mineaccorbtn:hover{
     
      width: 100%;
  
  }
  .mineaccorbtns {
       flex-wrap: wrap;
  }
  .mineaccorsec {
    justify-content: center;
    gap: 20px;
    
  }*/
    .slfsgroup {
        padding: 10px;
    }

    .activeminertext {
        position: absolute;
        top: 10px;
        right: 30px;
    }

    .wallettokencell {
        display: flex;
        padding: 3px 3px;
        border: 1px solid #ddd;
        border-radius: 4px;
        gap: 3px;
        justify-content: center;
        align-items: center;
    }

    .wallettoken {
        display: flex;
        gap: 10px;
        padding-bottom: 10px;
    }

    .wallettokenimg {
        width: 16px;
    }

    .walletokenp {
        font-size: 10px;
    }

    .walletrowimgp {
        font-size: 12px;
    }

    .walletpopupfooterp {
        font-size: 10px;
    }

    .latesttradetable th,
    #accordionExample .accordion-button,
    .mineaccorbtn,
    .mineaccorbtn:hover {
        font-size: 12px;
    }

    .latesttradetable td {
        font-size: 10px;
    }

    .minerownep {
        font-size: 8px;
    }

    .minerownep2 {
        font-size: 10px;
    }

    .minersownedp {
        font-size: 12px;
    }

    .mineaccorbtn {
        padding: 5px;
        font-size: 8px;
    }

    .wallettabsec .nav-link.typetabbtn {
        padding: 5px 0px;

        font-size: 10px;

    }

    .mineaccorimg {
        width: 75px;
        height: auto;
        margin: 0;
    }

    .slsecond span {
        font-size: 10px;
    }

    .slthird span {
        font-size: 8px;
    }

    .slfourthbtn {
        font-size: 10px;
    }

    .slfourthbtn img {
        width: 8px;
    }

    .slcollectbtn,
    .slstakebtn {
        width: 55px;
        font-size: 10px;
    }

    .slfourthbtns {
        gap: 5px;
    }

    .livebadge,
    .completedbadge,
    .upcomingbadge,
    .inactivebadge,
    .activebadge,
    .expirebadge {
        font-size: 6px;
        padding: 0px 8px;
    }

    .stakelivebtn,
    .stakelivebtn:hover {
        padding: 5px 10px;

        font-size: 10px;
    }

    .slfirst {
        margin-bottom: 10px;
    }

    .slsecond img {
        width: 15px;
    }

    .stakeliverow {
        margin-bottom: 10px;
    }

    .slidecontentp {
        font-size: 10px;
    }

    .tokenmorebtsn img {
        width: 15px;
    }

    .tokenmorebtsn span {
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 2px;
    }

    .tokenmorebtsn {
        gap: 5px;
    }

    .labelmax {
        width: 44px;
        font-size: 8px;
    }

    .labelegold {
        position: absolute;
        right: 48px;
        font-size: 10px;
    }

    .fs14 {
        font-size: 12px;
    }

    .fromreactangle {
        padding: 10px;
    }

    .sliderinput input {
        font-size: 10px;
    }

    .labelmax {
        font-size: 10px;
    }

    .labelegold {
        font-size: 12px;

    }

    .sliderlabel {
        font-size: 10px;
    }

    .labelavailbalp {
        font-size: 10px;
    }

    .stakegoldp {
        font-size: 12px;
        color: #4F6B75;
    }

    .appactiveminer {
        gap: 15px;
        padding: 10px;
    }

    .btn-bg-yellow {
        height: 30px;
        min-width: 90px;
    }

    /******landing ***/

    .egoldaccordianimg {
        padding: 5px;
        background-color: var(--primarycolor);
    }

    .egoldaccordianimg img {
        width: 26px;
        height: 26px;
    }

    .egoldcommunitybtnp {
        color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        padding-left: 10px;
    }

    .egoldcommunitybody p {
        font-size: 12px;
    }

    .egoldcommbtns a {
        font-size: 12px;
    }
}